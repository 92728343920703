import { POST_PREDICTIONS_JOKER, POST_PREDICTIONS_KINO } from "../actionTypes";

const INIT_STATE = {
  predictionsJokerData: null,
  predictionsKinoData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_PREDICTIONS_JOKER:
      return {
        ...state,
        predictionsJokerData: action.payload,
      };

    case POST_PREDICTIONS_KINO:
      return {
        ...state,
        predictionsKinoData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
