//SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';

//USER
export const USER_TOKEN_SET = "USER_TOKEN_SET";
export const USER_ID_SET = "USER_ID_SET";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const POST_SIGN_OUT = "POST_SIGN_OUT";
export const POST_USER_INFO = "POST_USER_INFO";
export const POST_USER_SINGLE = "POST_USER_SINGLE";
export const POST_USER_CHANGE_PASSWORD = "POST_USER_CHANGE_PASSWORD";
export const POST_ACCOUNT_CONTACT_OTPION_ADD = "POST_ACCOUNT_CONTACT_OTPION_ADD";
export const POST_ACCOUNT_CONTACT_OTPION_DELETE = "POST_ACCOUNT_CONTACT_OTPION_DELETE";
export const POST_ACCOUNT_CONTACT_OTPION_EDIT = "POST_ACCOUNT_CONTACT_OTPION_EDIT";

//Predictions
export const POST_PREDICTIONS_JOKER = "POST_PREDICTIONS_JOKER";
export const POST_PREDICTIONS_KINO = "POST_PREDICTIONS_KINO";