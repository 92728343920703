import {
  POST_PREDICTIONS_JOKER,
  POST_PREDICTIONS_KINO
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
  
  export const postPredictionsJoker = (postData) => async dispatch => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();

    postData.langLoc = userLocale;
    postData.isAdmin = false;
  

    const response = await axios.post(`predictions/joker`,postData);
    if (DEBUG_MODE){
      console.log(response.data);
    }
  
    dispatch({type: POST_PREDICTIONS_JOKER, payload: response.data});
  };

  export const postPredictionsKino = (postData) => async dispatch => {
    //Get user locale of the machine he is on
  const userLocale = General.getLocale();

  postData.langLoc = userLocale;
  postData.isAdmin = false;

  const response = await axios.post(`predictions/kino`,postData);
  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type:   POST_PREDICTIONS_KINO, payload: response.data});
};