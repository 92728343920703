import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Link, Route, Routes } from "react-router-dom";
import { useParams } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dashboard from '../dashboard';
import * as General from '../../helpers/General';
import SweetAlert from 'sweetalert2';
import { PRIMARY_COLOR } from '../../constants';
import { postLogout } from '../../redux/auth/action';
import JokerPage from '../joker/JokePage';
import QuizIcon from '@mui/icons-material/Quiz';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import KinoPage from '../kino/KinoPage';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Home = (props) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  // SELECTORS 
  const userInfoData = useSelector(({ auth }) => auth.userInfoData);
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const logoutData = useSelector(({ auth }) => auth.logoutData);

  // HOOKS VARIABLES
  const [darkModeChecked, setDarkModeChecked] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [open, setOpen] = useState(isSmUp);

  const [towShowDrawer, setToShowDrawer] = useState(!isSmUp);


  // VARIABLES
  const paramData = useParams();
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  // HOOKS
  useEffect(() => {
    setDarkModeChecked(General.getLightsMode() === "dark");
  }, []);

  useEffect(() => {
    setSelectedMenu(paramData["*"]);
  }, [paramData]);

  useEffect(() => {
    if (logoutData != null) {
      if (logoutData.status) {
        window.location.reload();
      }
    }
  }, [logoutData]);

  useEffect(() => {
    setOpen(isSmUp);
    setToShowDrawer(isSmUp);
  }, [isSmUp]);

  // FUNCTIONS
  const toggleDrawer = () => {
    if (!isSmUp){
      setToShowDrawer(!towShowDrawer)
    } else {
      setOpen(!open);
    }

  };

  const askToLogoutUser = (e) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.logout_title,
      text: trans.logout_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        // OK
        // LOGOUT
        let postData = {};
        dispatch(postLogout(postData));
      }
    });
  };

  const mainListItems = (
    <div>
      <ListItem button selected={selectedMenu === "dashboard"} component={Link} to="" onClick={() => setOpen(false)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_dashboard} />
      </ListItem>
      <ListItem button selected={selectedMenu === "joker"} component={Link} to="joker" onClick={() => setOpen(false)}>
        <ListItemIcon>
          <QuizIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_joker} />
      </ListItem>
      <ListItem button selected={selectedMenu === "kino"} component={Link} to="kino" onClick={() => setOpen(false)}>
        <ListItemIcon>
          <FiberSmartRecordIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_kino} />
      </ListItem>
    </div>
  );

  const secondaryListItems = (
    <div>
      <ListItem button selected={selectedMenu === "logout"} onClick={(e) => { askToLogoutUser(e); setOpen(false); }}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_sign_out} />
      </ListItem>
    </div>
  );

  const handleModeChange = (event) => {
    let checked = event.target.checked;
    setDarkModeChecked(event.target.checked);
    if (checked) {
      General.storeLightsMode("dark");
    } else {
      General.storeLightsMode("light");
    }
    props.onModeChange(checked);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {trans.dashboard_header_title}{process.env.REACT_APP_VERSION}
          </Typography>
          <FormGroup style={{ marginLeft: '8px' }}>
            <FormControlLabel control={<Switch checked={darkModeChecked} onChange={handleModeChange} />} label={General.getLightsMode() === "dark" ? trans.dark_mode : trans.light_mode} />
          </FormGroup>
        </Toolbar>
      </AppBar>
     { towShowDrawer ? <Drawer
        variant={"permanent"}
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <AccountBoxIcon fontSize="medium" />
            &nbsp;
            <Typography variant="body1">
              {userInfoData.userInfo.fullName}
            </Typography>
          </div>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer> : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <div>
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path='joker' element={<JokerPage />}></Route>
            <Route path='kino' element={<KinoPage />}></Route>
            {/* Add other routes as needed */}
          </Routes>
        </div>
      </Box>
    </Box>
  );
}

export default Home;
