import React, { useState, useEffect } from 'react';
import './joker.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import CustomTitle from '../global/CustomTitle';
import { postPredictionsJoker } from '../../redux/predictions/action';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Loading from '../loading';

const JokerPage = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const predictionsJokerData = useSelector(({ predictions }) => predictions.predictionsJokerData);

  const trans = settingsData.trans;

  const [loading, setLoading] = useState(false);
  const [lastDrawsCount, setLastDrawsCount] = useState(50);
  const [jokerNumbres, setJokerNumbers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    dispatch(postPredictionsJoker({ lastDrawsCount }));
  };

  useEffect(() => {
    if (predictionsJokerData != null) {
      setLoading(false);
      setJokerNumbers(predictionsJokerData.joker_numbres);
    }
  }, [predictionsJokerData]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <CustomTitle>{`${trans.predictions_joker_title}`}</CustomTitle>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={trans.joker_last_draws_count}
                  type="number"
                  value={lastDrawsCount}
                  onChange={(e) => setLastDrawsCount(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={getData}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? trans.joker_reload_data_loading : trans.joker_reload_data}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{trans.joker_predictions_draw_title}</Typography>

            {jokerNumbres.length > 0 ? (
              jokerNumbres.map((draw, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{trans.joker_predictions_column} {index + 1}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      gap: 1,
                    }}
                  >
                    {draw.winningNumbers.map((number, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          backgroundColor: '#2196f3',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                        }}
                      >
                        {number}
                      </Box>
                    ))}
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: '#ffb74d',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#000',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                      }}
                    >
                      {draw.jokerNumber}
                    </Box>
                  </Box>
                </Box>
              ))
            ) : loading ? <Loading/> : (
              <div>{trans.joker_reload_data_empty}</div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JokerPage;
