import React, { useState,useEffect,CSSProperties } from 'react';
import './dashboard.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import Paper from '@mui/material/Paper';
import CustomTitle from '../global/CustomTitle';

const Dashboard = () => {

  const dispatch = useDispatch();
  const settingsData = useSelector(({settings}) => settings.settingsData);

  const userInfoData = useSelector(({auth}) => auth.userInfoData);

  const trans = settingsData.trans;


  //HOOKS
  useEffect(() => { //ONLOAD
  //  getDashboardData();
  }, []);


  const getDashboardData = () => {
    // setLoadingExpiredUsers(true);
    // dispatch(postDashboardMain({}));
  }


  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.dashboard_welcome}, ${userInfoData.userInfo.fullName}`}</CustomTitle>    
            </Paper>
          </Grid>

        </Grid>

      </Container>
  );
}

export default Dashboard;