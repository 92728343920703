const environment = process.env.REACT_APP_ENV;
const envType = environment === `production` ? `production` : `staging`;
const isProduction = envType == "production";

// console.log("environment",environment);

//GENERAL VALUES
export const WEB_SITE_NAME = "Apiach Platform";
export const DEBUG_MODE = !isProduction;
export const DATE_FORMAT = "DD/MM/yyyy HH:mm"
export const DATE_FORMAT_NO_TIME = "DD/MM/yyyy"
export const PICKER_DATE_FORMAT = "dd/MM/yyyy HH:mm"
export const PRIMARY_COLOR = "#fdc912";

//URLS
export const WEB_SITE_URL = "https://apiach.com/";
export const API_URL = DEBUG_MODE ? "https://devservices.apiach.com/v1/" : "https://services.apiach.com/v1/";
export const SOCKET_URL = DEBUG_MODE ? "https://devservices.apiach.com" : "https://services.apiach.com";

export const DATE_TIME_FORMAT = "DD/MM/yyyy HH:mm"

export const LIGHTS_MODE = "LIGHTS_MODE_VALUE";


//DATES
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

//IDS
export const SOURCE_TWITTER_ID = "";

//OPTIONS
export const SLUG_OPTIONS = {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()/'"!:@,]/g, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: false,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
};

export const editorConfig = {
    readonly: false,
    iframe: true,
    video: true,
    height: 300
}


export const editorConfigSmall = {
    readonly: false,
    iframe: true,
    video: true,
    height: 150,
    placeholder: ""
}
